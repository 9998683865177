
import styled from 'styled-components';
import mazowszeImage from '../../assets/logo_mazowsze.png';
import raszynImage from '../../assets/raszyn_black.svg';

const Container = styled.div`
    font-size: .8em;
    text-align: center;

    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;

    &>* {
        margin: 1.5em auto;
    }

    img.mazowsze {
        width: calc(100% - 84px);
        max-width: calc(350px + 42px + 42px);

        background: white;
        padding: 53px 42px 32px;
    }

    .raszyn {

        display: flex;
        align-items: center;

        img {
            width: 100px;
            max-width: 100%;
        }
    }

    @media (max-width: 1200px) {
    flex-direction: column;
    }
`;

export default function Sponsor() {
    return <Container>
        <div>
            <p>Zadanie dofinansowane ze środków z budżetu Województwa Mazowieckiego</p>
            <img className="mazowsze" src={mazowszeImage} alt="Mazowsze - serce Polski" />
        </div>
        <div className="raszyn">
            <img src={raszynImage} alt="Raszyn" />
        </div>
    </Container>
}

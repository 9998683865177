import { GameModuleState, GameModuleAction, GAME_MODULE_ACTION, initGameModuleState } from ".";
import { SCENARIO } from "./scenario";
import { GamePoint, GAME_STEP_TYPE } from "./types";

export default function UserReducer(state: GameModuleState, action: GameModuleAction): GameModuleState {
    const { type, payload } = action;
    switch (type) {
        case GAME_MODULE_ACTION.SET_ACTIVE_POINT_POSITION:
            const gamePoints = [
                ...state.gamePoints
            ]

            const step = SCENARIO.steps.find(s => s.id === state.activeGameStep);

            if (step && step.type === GAME_STEP_TYPE.GEO_POINT) {
                return {
                    ...state,
                    gamePoints: gamePoints.reduce((prev: GamePoint[], next: GamePoint) => {

                        if (next.id === step.contentId) {
                            return [
                                ...prev,
                                {
                                    ...next,
                                    position: payload.position
                                }
                            ]
                        };

                        return [
                            ...prev,
                            next
                        ]
                    }, [])
                }
            }
            break;

        case GAME_MODULE_ACTION.RESET_GAME:
            return {
                ...initGameModuleState
            }

        case GAME_MODULE_ACTION.START_GAME:
            return {
                ...state,
                gameStarted: true
            }

        case GAME_MODULE_ACTION.SET_GAME_STEP:
            return {
                ...state,
                activeGameStep: payload.id
            }

        case GAME_MODULE_ACTION.END_GAME:
            return {
                ...state,
                gameComplete: true
            }
    }

    return state;
}

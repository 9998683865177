export type DialogData = {

}

export interface GeoPoint {
    lat: number,
    lng: number
}

export interface GamePoint {
    position: GeoPoint,
    name: string[],
    id: string
}

export interface GameStep {
    id: string
    type: GAME_STEP_TYPE,
    contentId: string,
    nextStep: string | null
}

export interface GameScenario {
    steps: GameStep[],
    startStep: string
}

export enum GAME_STEP_TYPE {
    GEO_POINT = 'geo',
    DIALOG = 'dialog',
    WIDGET = 'widget'
}

export interface GameDialog {
    id: string,
    nodes: DialogNode[],
    startNode: string
}

export interface DialogNode {
    id: string,
    content: DialogNodeContent[],
    actions: DialogAction[]
}

export interface DialogNodeContent {
    text: string,
    persons?: {
        id: string
    }[],
    talkingPosition?: 1 | 2,
    talkingName?: string
}

export interface DialogAction {
    text: string,
    nextNode: string | null
}

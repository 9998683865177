import styled from "styled-components";

type Props = React.PropsWithChildren<{}>;

const Container = styled.div`
    background: white;
    border-radius: 5rem;

    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    .inner {
        padding: 1rem 4rem;
    }

    @media (max-width: 1200px) {
        border-radius: 45px;

        .inner {
            padding: 1rem 2rem;
        }
    }
`;
export default function Box({ children }: Props) {
    return <Container>
        <div className="inner">
            {children}
        </div>
    </Container>
}

import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

import { getHomeRoute, getWikiGatePageRoute, getWikiPageRoute, getGamePageRoute, getRulesPageRoute, getPolicyRoute } from './routes/routes';
import React, { Suspense } from 'react';
import Loader from './components/loader';
import PageHeader from './components/header';
import { useMainModuleState } from './modules/main';
import CookiesInfo from './components/cookies';
import PageFooter from './components/footer';
import PolicyPage from './routes/policy';
import { useEffect } from 'react';
import { CONFIG_MODULE_ACTION, useConfigModuleDispatch } from './modules/config';

const HomePage = React.lazy(() => import('./routes/home'));
const WikiGatePage = React.lazy(() => import('./routes/wiki/gate'));
const WikiPage = React.lazy(() => import('./routes/wiki'));
const GamePage = React.lazy(() => import('./routes/game'));
const RulesPage = React.lazy(() => import('./routes/rules'));

export default function App() {

    const { showHeader } = useMainModuleState();

    return (
        <>
            <BrowserRouter>
                <CookiesInfo />
                {showHeader && <PageHeader />}
                <main>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            <Route path={getHomeRoute()} element={<HomePage />} />
                            <Route path={getRulesPageRoute()} element={<RulesPage />} />
                            <Route path={getGamePageRoute()} element={<GamePage />} />
                            <Route path={getWikiGatePageRoute()} element={<WikiGatePage />} />
                            <Route path={getWikiPageRoute()} element={<WikiPage />} />

                            <Route path={getPolicyRoute()} element={<PolicyPage />} />
                            <Route path={'/debug-on'} element={<DebugOn />} />
                            <Route path={'/debug-off'} element={<DebugOff />} />
                            <Route element={<HomePage />} />
                        </Routes>
                    </Suspense>
                </main>
                {showHeader && <PageFooter />}
            </BrowserRouter>
        </>
    )
}


function DebugOn() {
    const navigate = useNavigate();
    const dispatch = useConfigModuleDispatch();

    useEffect(() => {
        dispatch({
            type: CONFIG_MODULE_ACTION.SET_DEBUG,
            payload: true
        });

        navigate(getHomeRoute(), { replace: true });
    }, [navigate, dispatch]);

    return <></>
}


function DebugOff() {
    const navigate = useNavigate();
    const dispatch = useConfigModuleDispatch();

    useEffect(() => {
        dispatch({
            type: CONFIG_MODULE_ACTION.SET_DEBUG,
            payload: false
        });

        navigate(getHomeRoute(), { replace: true });
    }, [navigate, dispatch]);

    return <></>
}

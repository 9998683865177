import { GamePoint } from "./types";

const GEO_POINTS: GamePoint[] = [
    {
        id: '1',
        position: {
            lat: 52.15414609929565,
            lng: 20.91776308234229
        },
        name: ['Kapliczka. Figura Świętych.', 'ul. Cypriana Godebskiego 15b'],
    },
    {
        id: '2',
        position: {
            lat: 52.15376456305676,
            lng: 20.919047105253416
        },
        name: ["Kopiec i szaniec artyleryjski", "przy Aleja Krakowska 27C"]
    },
    {
        id: '3',
        position: {
            lat: 52.15068318700419,
            lng: 20.915941093761404
        },
        name: ["Kościół pw. św. Szczepana", "Aleja Krakowska 6"]
    },
    {
        id: '4',
        position: {
            lat: 52.15020401222448,
            lng: 20.915236864370268
        },
        name: ["Austeria", "Aleja Krakowska 1"]
    },
    {
        id: '5',
        position: {
            lat: 52.15023262101001,
            lng: 20.915917247818797
        },
        name: ["Dawny rynek", "na tyłach Austerii"]
    },
    {
        id: '6',
        position: {
            lat: 52.14624294246248,
            lng: 20.91602636907051
        },
        name: ["Pomnik Bitwa Pod Raszynem 19&nbsp;kwietnia 1809"]
    },
    {
        id: '7',
        position: {
            lat: 52.15437063402944,
            lng: 20.923042869960792
        },
        name: ["Szkoła Podstawowa im. Cypriana Godebskiego", "Szkolna 2"]
    }
]

// const GEO_POINTS: GamePoint[] = [
//     {
//         id: '1',
//         position: {
//             lat: 52.202590,
//             lng: 20.974398
//         },
//         name: ['Kapliczka. Figura Świętych', 'ul.Cypriana Godebskiego 15b', '05-090 Raszyn'],
//     },
//     {
//         id: '2',
//         position: {
//             lat: 52.201338529284506,
//             lng: 20.97331036522059,
//         },
//         name: ["Kopiec", "na tyłach Aleja Krakowska 27C", "05-090 Raszyn"]
//     },
//     {
//         id: '3',
//         position: {
//             lat: 52.201076,
//             lng: 20.975252
//         },
//         name: ["Kościół pw. św. Szczepana", "Aleja Krakowska 6", "05-090 Raszyn"]
//     },
//     {
//         id: '4',
//         position: {
//             lat: 52.20060451761823,
//             lng: 20.975996023624745
//         },
//         name: ["Austeria", "Aleja Krakowska 1", "05-090 Raszyn"]
//     },
//     {
//         id: '5',
//         position: {
//             lat: 52.199767561074,
//             lng: 20.97765745234053
//         },
//         name: ["dawny rynek", "Aleja Krakowska 1", "05-090 Raszyn"]
//     },
//     {
//         id: '6',
//         position: {
//             lat: 52.199072410161335,
//             lng: 20.978670241115775
//         },
//         name: ["polana przed pomnikiem Bitwa Pod Raszynem 19 kwietnia 1809"]
//     },
//     {
//         id: '7',
//         position: {
//             lat: 52.198133125453474,
//             lng: 20.977945736811087
//         },
//         name: ["Szkoła Podstawowa im. Cypriana Godebskiego", "Szkolna 2", "05-090 Raszyn"]
//     }
// ]

export default GEO_POINTS;

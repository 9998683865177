export function getHomeRoute() {
    return '/';
}

export function getDialogPageRoute(id: string = ':id') {
    return '/dialog/' + id;
}

export function getCompassPageRoute() {
    return '/compass';
}

export function getWikiPageRoute() {
    return '/wiki';
}

export function getWikiGatePageRoute() {
    return '/wiki-gate';
}

export function getRulesPageRoute() {
    return '/instrukcja';
}

export function getGamePageRoute() {
    return '/game';
}

export function getPolicyRoute() {
    return '/polityka';
}

export function getAccessRoute() {
    return '/deklaracja';
}

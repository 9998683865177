import { GameScenario, GAME_STEP_TYPE } from "./types";

export const SCENARIO: GameScenario = {
    steps: [
        {
            type: GAME_STEP_TYPE.GEO_POINT,
            id: 'geo-1',
            contentId: '1',
            nextStep: 'p-3'
        },
        {
            type: GAME_STEP_TYPE.DIALOG,
            id: 'p-3',
            contentId: '3',
            nextStep: 'geo-2'
        },
        {
            type: GAME_STEP_TYPE.GEO_POINT,
            id: 'geo-2',
            contentId: '2',
            nextStep: 'p-4'
        },
        {
            type: GAME_STEP_TYPE.DIALOG,
            id: 'p-4',
            contentId: '4',
            nextStep: 'w-1'
        },
        {
            type: GAME_STEP_TYPE.WIDGET,
            id: 'w-1',
            contentId: '1',
            nextStep: 'geo-3'
        },
        {
            type: GAME_STEP_TYPE.GEO_POINT,
            id: 'geo-3',
            contentId: '3',
            nextStep: 'p-5'
        },
        {
            type: GAME_STEP_TYPE.DIALOG,
            id: 'p-5',
            contentId: '5',
            nextStep: 'w-2'
        },
        {
            type: GAME_STEP_TYPE.WIDGET,
            id: 'w-2',
            contentId: '2',
            nextStep: 'geo-4'
        },
        {
            type: GAME_STEP_TYPE.GEO_POINT,
            id: 'geo-4',
            contentId: '4',
            nextStep: 'p-6'
        },
        {
            type: GAME_STEP_TYPE.DIALOG,
            id: 'p-6',
            contentId: '6',
            nextStep: 'w-3'
        },
        {
            type: GAME_STEP_TYPE.WIDGET,
            id: 'w-3',
            contentId: '3',
            nextStep: 'geo-5'
        },
        {
            type: GAME_STEP_TYPE.GEO_POINT,
            id: 'geo-5',
            contentId: '5',
            nextStep: 'p-7'
        },
        {
            type: GAME_STEP_TYPE.DIALOG,
            id: 'p-7',
            contentId: '7',
            nextStep: 'w-4'
        },
        {
            type: GAME_STEP_TYPE.WIDGET,
            id: 'w-4',
            contentId: '4',
            nextStep: 'geo-6'
        },
        {
            type: GAME_STEP_TYPE.GEO_POINT,
            id: 'geo-6',
            contentId: '6',
            nextStep: 'p-8'
        },
        {
            type: GAME_STEP_TYPE.DIALOG,
            id: 'p-8',
            contentId: '8',
            nextStep: 'w-5'
        },
        {
            type: GAME_STEP_TYPE.WIDGET,
            id: 'w-5',
            contentId: '5',
            nextStep: 'geo-7'
        },
        {
            type: GAME_STEP_TYPE.GEO_POINT,
            id: 'geo-7',
            contentId: '7',
            nextStep: 'p-9'
        },
        {
            type: GAME_STEP_TYPE.DIALOG,
            id: 'p-9',
            contentId: '9',
            nextStep: 'w-6'
        },
        {
            type: GAME_STEP_TYPE.WIDGET,
            id: 'w-6',
            contentId: '6',
            nextStep: 'p-10'
        },
        {
            type: GAME_STEP_TYPE.DIALOG,
            id: 'p-10',
            contentId: '10',
            nextStep: null
        }
    ],
    startStep: 'geo-1'
}

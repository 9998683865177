import styled from "styled-components"
import raszynLogoWhite from '../../assets/raszyn_white.svg';
import raszynLogoRed from '../../assets/raszyn_red.svg';

const Container = styled.header`

    background: url(${raszynLogoWhite}) 10rem 50% no-repeat var(--color1);
    background-size: auto 80%;

    min-height: 100px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 3rem;

    border-radius: 0 0 5rem 5rem;

    position:relative;
    /* overflow: hidden; */

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -1rem;
        right: -1rem;
        bottom: -.5rem;

        border: 1px solid var(--color3);
        border-top: none;
        border-radius: 0 0 7rem 7rem;
    }

    h1 {
        text-indent: -9999px;
    }

    @media (max-width: 1200px) {
        background-image: url(${raszynLogoRed});
        background-position: 50% 50%;
        background-color: transparent;
        border-top: 10px solid var(--color1);

        margin: 0 auto 1rem;

        &:before {
            display: none;
        }
    }
`;

export default function PageHeader() {
    return <Container>
        <h1>Projekt Raszyn 1809</h1>
    </Container>
}

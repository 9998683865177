import styled from "styled-components"
import { Link } from "react-router-dom";
import { getAccessRoute, getPolicyRoute } from "../../routes/routes";

const Container = styled.footer`

    background: var(--color3);
    background-size: auto 80%;

    min-height: 100px;
    max-width: 1200px;
    width: 100%;
    margin: 3rem auto 0;

    border-radius: 5rem 5rem 0 0;

    position:relative;
    /* overflow: hidden; */

    display: flex;
    justify-content: center;
    align-items: center;

    align-self: end;


    h1 {
        text-indent: -9999px;
    }

    nav {
        width: 60%;
        margin: 0 auto;

        display: flex;
        justify-content: space-around;
        align-items: center;

        a {
            display: inline-block;
            color: white;
            text-decoration: none;
        }
    }

    @media (max-width: 750px) {
        nav {
            flex-direction: column;

            a {
                margin: .3em 0;
            }
        }
    }
`;

export default function PageFooter() {
    return <Container>
        <nav>
            <Link to={getPolicyRoute()}>Polityka prywatności</Link>
            <Link to={getAccessRoute()}>Deklaracja dostępności</Link>
        </nav>
    </Container>
}

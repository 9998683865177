import { Link, useNavigate } from "react-router-dom";
import Box from "../../components/box";
import Sponsor from "../../components/sponsor";
import { getRulesPageRoute } from "../routes";

export default function PolicyPage() {

    const navigate = useNavigate();

    return <>
        <Box>
            <h2>Polityka Prywatności</h2>

            <p>
                Stowarzyszenie “Projekt Raszyn” z siedzibą w Raszynie przy ulicy Mieszka I 1, jako właściciel i Administrator serwisu: http://www.raszyngra.pl/ (dalej: Serwis) przedstawia poniżej informacje dotyczące zastosowania i wykorzystania w Serwisie plików cookie.
            </p>

            <p>
                W Serwisie administrowanym przez Stowarzyszenie “Projekt Raszyn” wykorzystywane są następujące rodzaje plików cookies:
            </p>
            <ul>
                <li>
                    pliki typu necessary – niezbędne do prawidłowego funkcjonowania strony
                </li>
                <li>
                    pliki typu statistics – służące do zbierania statystyk odwiedzin i aktywności za pomocą narzędzia Google Analytics
                </li>
                <li>
                    pliki zbierające dane o lokalizacji urządzenia – wykorzystywane w grze mobilnej
                </li>
            </ul>

            <p>
                Nasze pliki cookies to:
            </p>
            <ul>
                <li>
                    pliki sesyjne – wygasają po zakończeniu sesji np. po wylogowaniu się ze strony internetowej, wg parametrów określonych w Twojej przeglądarce internetowej
                </li>
                <li>
                    pliki trwałe – nie są kasowane w momencie zamknięcia okna przeglądarki; wykorzystywane są, aby pomóc nam wspierać komfort korzystania z naszych Serwisów (np. zmiana wyglądu lub wersji stron)
                </li>
            </ul>

            <p>
                Serwis zewnętrzny zapewniający usługi analityczne: Google LLC, ma własną politykę cookies <a href="https://policies.google.com/technologies/cookies">(https://policies.google.com/technologies/cookies)</a>. Może dodać dane zebrane podczas Twojej wizyty do zbioru danych pozyskanych na Twój temat z innych źródeł.
            </p>

            <p>
                W każdej chwili możesz edytować ustawienia prywatności na Twoim urządzeniu w ramach ustawień swojej przeglądarki m.in. w zakresie instalowania plików cookies. W każdej chwili możesz też usunąć z pamięci swojego urządzenia pliki cookies zapisane w trakcie przeglądania naszych Serwisów. Ograniczenia w stosowaniu plików cookies mogą utrudnić lub uniemożliwić komfort korzystania z naszych Serwisów.
            </p>
            <p>
                Instalowanie plików cookies lub uzyskiwanie do nich dostępu nie powoduje zmian w Twoim urządzeniu ani w oprogramowaniu zainstalowanym na tym urządzeniu.
            </p>

            <nav className="button-list" aria-label="Porwót">
                <Link className="button" to={getRulesPageRoute()} onClick={() => navigate(-1)}>Powrót</Link>
            </nav>

        </Box>
        <Sponsor />
    </>
}
